import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import MatchAccolades from "@/game-eft/components/MatchAccolades.jsx";
import MatchHitStats from "@/game-eft/components/MatchHitStats.jsx";
import MatchMySquad from "@/game-eft/components/MatchMySquad.jsx";
import MatchStatsBreakdown from "@/game-eft/components/MatchStatsBreakdown.jsx";
import MatchWeaponsTable from "@/game-eft/components/MatchWeaponsTable.jsx";
import getMapName from "@/game-eft/utils/get-map-name.mjs";
import getRaidStatus from "@/game-eft/utils/get-raid-status.mjs";
import useMapImages from "@/game-eft/utils/use-map-images.mjs";
import DotIcon from "@/inline-assets/dot.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import ShareButton from "@/shared/ShareButton.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Match() {
  const { t } = useTranslation();
  const {
    parameters: [profileId, gameId],
  } = useRoute();
  const state = useSnapshot(readState);
  const { getMapImage } = useMapImages();
  const $match = state.eft.matches[gameId];
  const match = useEvalState($match);
  const player = match?.playerMatches.find(
    (i) => i.player.accountId === profileId,
  );
  const map = match?.gameMap.key ?? "";
  const mapName = t(...getMapName(map));
  const { translation } = getRaidStatus(player?.exitReason);
  const raidStatus = t(...translation);
  return (
    <SharedMatchLayout
      match={$match}
      title={raidStatus}
      image={getMapImage(map)}
      imageLink={`/eft/profile/${profileId}`}
      borderColor="var(--shade3)"
      underTitle={
        <div className="flex align-center gap-2 color-shade2">
          {[
            mapName,
            player?.faction,
            t("common:minutes", "{{minutes}} mins", {
              minutes: formatDuration(player?.duration ?? 0, "m:ss"),
            }),
          ]
            .filter((i) => i)
            .map((i) => (
              <React.Fragment key={i}>
                <span className="type-callout color-shade2">{i}</span>
                <DotIcon style={{ color: "var(--shade3)" }} />
              </React.Fragment>
            ))}
          {!!match?.startedAt && (
            <div className="type-callout color-shade2">
              <TimeAgo
                date={new Date(match?.startedAt)}
                className={undefined}
              />
            </div>
          )}
          <DotIcon style={{ color: "var(--shade3)" }} />
          <ShareButton />
        </div>
      }
    >
      <MainColumnsContainer>
        <div
          className="span-1 flex direction-column"
          style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
        >
          <MatchAccolades />
          <MatchWeaponsTable />
        </div>
        <div className="span-2">
          <MatchHitStats />
          <MatchStatsBreakdown />
          <MatchMySquad />
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}

export function meta([accountId]) {
  const profile = readState.eft.profiles[accountId];
  const name =
    !profile || profile instanceof Error ? "Unknown" : profile.username;
  return {
    title: [
      "eft:meta.match.title",
      "{{name}}'s Match Stats – EFT – Blitz Escape From Tarkov",
      { name },
    ],
    description: [
      "eft:meta.match.description",
      "Match Stats for {{name}}",
      { name },
    ],
  };
}
